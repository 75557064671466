"use client";

import classNames from "classnames";
import _debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { PortableText } from "@portabletext/react";
import { serializers } from "@/utils/sanity";

// import useWindowDimensions from "../../../../utils/dom";
import { getRandomArbitraryInt } from "@/utils/math";
import { InternalExternalLink } from "@/components/common/InternalExternalLink";
import { NSEmbed } from "@/components/common/NSEmbed";
import { NSImage } from "@/components/common/NSImage";
import styles from "./InviteCard.module.scss";

const InviteCard = ({
  image,
  url,
  isPortrait,
  cardLabel,
  mediaText,
  textArea,
  hideInfo,
}) => {
  const cls = classNames(
    {
      [styles.isPortrait]: isPortrait,
    },
    {
      [styles.inviteCard]: true,
    },
    {
      [styles.isNotPortrait]: !isPortrait,
    },
  );

  const ref = useRef(null);
  const cardRef = useRef(null);

  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const [mBottom, setMBottom] = useState(0);
  const [animate, setAnimate] = useState(false);

  const setPosition = () => {
    const infoWidth = ref.current ? ref.current.offsetWidth : 0;
    const infoHeight = ref.current ? ref.current.offsetHeight : 0;
    const cardWidth = cardRef.current ? cardRef.current.offsetWidth : 0;
    const cardHeight = cardRef.current ? cardRef.current.offsetHeight : 0;

    if (cardWidth >= 768) {
      setXPos(getRandomArbitraryInt(0, cardWidth / 2));
      setYPos(getRandomArbitraryInt(0, infoHeight / 2));
      setMBottom(128);
    } else {
      setXPos(getRandomArbitraryInt(10, cardWidth - 10 - infoWidth));
      const yPosition = getRandomArbitraryInt(0, infoHeight / 10);
      setYPos(yPosition);
      setMBottom(infoHeight - (cardHeight - yPosition) + 32);
    }
  };

  useEffect(() => {
    setPosition();
  }, []);

  useEffect(() => {
    const handleResize = _debounce(() => {
      setPosition();
      setAnimate(true);
    }, 150);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const visualType = useMemo(() => {
    let type = "";

    if (image?.image) {
      type = "image";
    }
    if (mediaText?.media?.mediaUrl) {
      type = "media";
    }
    return type;
  }, [image, mediaText]);

  return (
    <div className={styles.cardWrapper}>
      <div className={cls}>
        <div
          ref={cardRef}
          className={styles.inviteCardInner}
          // style={{ marginBottom: mBottom }}
        >
          {visualType === "image" && (
            <div className={styles.cardImage}>
              <NSImage image={image?.image} alt={image?.altText || ""} />
              {url && (
                <InternalExternalLink to={url}>
                  {image?.altText}
                </InternalExternalLink>
              )}
            </div>
          )}
          {visualType === "media" && (
            <div className={styles.cardEmbed}>
              <NSEmbed
                mediaUrl={mediaText?.media?.mediaUrl}
                auto={true}
                muted={true}
                absolute={true}
              />
            </div>
          )}
        </div>
      </div>
      {hideInfo === false && (
        <div
          ref={ref}
          className={`${styles.cardInfo} ${styles.inviteInfo} ${
            animate && styles.animateInfo
          }`}
          style={{ left: xPos, bottom: yPos }}
        >
          <div className={classNames("label", styles.inviteInfoLabel)}>
            {cardLabel && cardLabel.cardLabelName}
          </div>
          <div className="date">
            <InternalExternalLink to={url}>
              {textArea && (
                <PortableText value={textArea} components={serializers} />
              )}
            </InternalExternalLink>
          </div>
        </div>
      )}
    </div>
  );
};

export { InviteCard };
